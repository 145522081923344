// extracted by mini-css-extract-plugin
export default {"home":"home_home__Piz6+","chartCard":"home_chartCard__czYGQ","title":"home_title__y4spq","tag":"home_tag__p0a6U","searchBox":"home_searchBox__K4dri","searchButton":"home_searchButton__XYzdQ","innerTitle":"home_innerTitle__0h3a8","chart":"home_chart__tJOSD"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"home\":\"home_home__Piz6+\",\"chartCard\":\"home_chartCard__czYGQ\",\"title\":\"home_title__y4spq\",\"tag\":\"home_tag__p0a6U\",\"searchBox\":\"home_searchBox__K4dri\",\"searchButton\":\"home_searchButton__XYzdQ\",\"innerTitle\":\"home_innerTitle__0h3a8\",\"chart\":\"home_chart__tJOSD\"}";
        // 1728725594482
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  