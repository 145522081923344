import gcoord from 'gcoord';
import { isNil } from 'lodash';
import { ownRound } from '@/utils/math';
import { formatTime } from '@/utils/formatTime';
import { formatGpsSpeed } from '@/utils/gps';
import { displayJtDeviceAlarm } from '@/utils/jtDeviceAlarm';
import { currentLocationRequest } from '@/service/trackPlayback';
import { alarmTypeData } from '@/constants/alarm';
import carIcon from '@/assets/image/carIcon.png';
import onlineCarIcon from '@/assets/image/onlineCarIcon.png';
import { driveVideoErrorMessage } from '@/constants/driveVideo';

// 展示驾驶行为的事件类型
export const renderEventType = (data: {
  type: string;
  alarmType: number | string;
  info?: any;
}) => {
  const { type, alarmType, info } = data;
  if (['gps', 'gps808'].includes(type)) {
    return 'GPS信息';
  } else if (type === 'signInfo') {
    return '车身信息';
  } else if (type === 'modem') {
    return '震动';
  } else if (type === 'alarm') {
    const matchAlarmType = alarmTypeData.find(
      (item: any) => item.value === Number(alarmType)
    );
    return matchAlarmType?.label || alarmType;
  } else if (type === 'alarm808') {
    return displayJtDeviceAlarm(info);
  }
  return '';
};

// 驾驶行为的点位信息弹窗展示
export const showInfoWindow = (point: any, mapRef: any, toCenter?: boolean, address?: any) => {
  const originData = point?.target?.originData || {};
  const { type, vehicleSpeed, gpsSpeed, reportedTime, descText } = originData;
  let content = '';
  if (type === "signInfo") {
    content = `<div><span class='gdInfoLabel'>车身信息</span></div>
      <div><span class='gdInfoLabel'>车身速度: </span> ${ownRound(vehicleSpeed, 1)} </div>
      <div><span class='gdInfoLabel'>时间: </span> ${reportedTime} </div>
      <div><span class='gdInfoLabel'>车身信息: </span> ${descText} </div>`;
  } else if (type === "alarm") {
    content = `<div><span class='gdInfoLabel'>报警信息</span></div>
    <div><span class='gdInfoLabel'>类型: </span> ${renderEventType(originData)} </div>
    <div><span class='gdInfoLabel'>车身速度: </span> ${ownRound(vehicleSpeed, 1)} </div>
    <div><span class='gdInfoLabel'>时间: </span>${reportedTime}</div>`;
  } else if (type === "gps") {
    content = `<div><span class='gdInfoLabel'>gps信息</span></div>
    <div><span class='gdInfoLabel'>GPS速度: </span>${ownRound(gpsSpeed, 1)}</div>
    <div><span class='gdInfoLabel'>时间: </span>${reportedTime}</div>
    <div><span class='gdInfoLabel'>地点: </span>${address}</div>`;
  } else if (type === "modem") {
    content = `<div><span class='gdInfoLabel'>记录仪信息</span></div>
    <div><span class='gdInfoLabel'>时间: </span>${reportedTime}</div>`;
  } else if (type === "alarm808") {
    content = `<div><span class='gdInfoLabel'>adas报警信息</span></div>
    <div><span class='gdInfoLabel'>时间: </span>${reportedTime}</div>
    <div><span class='gdInfoLabel'>车身速度: </span> ${ownRound(vehicleSpeed, 1)} </div>
    <div><span class='gdInfoLabel'>GPS速度: </span>${ownRound(gpsSpeed, 1)}</div>
    <div><span class='gdInfoLabel'>信息: </span>${descText}</div>
    `;
  }
  if (toCenter) {
    // 地图中心移动到弹窗位置
    mapRef.current.setCenter(point.lnglat);
  }
  const mapInfoWindow = new window.AMap.InfoWindow({ content });
  mapInfoWindow.open(mapRef.current, point.lnglat);
}

/**
 * 地图上显示GPS点位
 * @param data gps数据
 * @param mapRef 地图实例
 * @param playbackUse 轨迹回放页面使用(会有字段使用的特殊处理)
 */
export const renderGps = (
  data: any,
  mapRef: any,
  playbackUse?: boolean,
  showDir?: boolean,
  hiddenItineraryId?: boolean
) => {
  const _getAddress = (data: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      window.AMap.plugin('AMap.Geocoder', function () {
        const geocoder = new window.AMap.Geocoder();
        geocoder.getAddress([data.lng, data.lat], (status: string, result: any) => {
          if (status === 'complete' && result.regeocode) {
            resolve(result.regeocode.formattedAddress);
          } else {
            reject(status);
          }
        });
      });
    });
  }

  const points: any = [];
  const pathArr: any = [];
  data.reduce((prev: any, curr: any) => {
    const transformGcoord = gcoord.transform(
      [curr.lat, curr.lon],
      gcoord.WGS84,
      gcoord.AMap
    );
    const lngLat = new window.AMap.LngLat(
      transformGcoord[0],
      transformGcoord[1]
    );
    const currData = playbackUse
      ? { ...curr, type: 'gps', gpsSpeed: curr.speed, transformGcoord }
      : { ...curr, transformGcoord };
    points.push({
      lnglat: lngLat,
      style: 0,
      originData: currData
    });
    if (hiddenItineraryId) {
      return curr;
    }
    // 根据行程ID进行区分
    if (prev) {
      if (curr.itineraryId !== prev.itineraryId) {
        if (curr.lat !== prev.lat || curr.lon !== prev.lon) {
          const prevTransformGcoord = gcoord.transform(
            [prev.lat, prev.lon],
            gcoord.WGS84,
            gcoord.AMap
          );
          const prevLngLat = new window.AMap.LngLat(
            prevTransformGcoord[0],
            prevTransformGcoord[1]
          );
          pathArr.push([prevLngLat, lngLat]);
        } else {
          pathArr.push([lngLat]);
        }
      } else {
        pathArr[pathArr.length - 1].push(lngLat);
      }
    } else {
      pathArr.push([lngLat]);
    }
    return curr;
  }, undefined);

  if (hiddenItineraryId) {
    new window.AMap.Polyline({
      map: mapRef.current,
      path: points.map((item: any) => item.lnglat),
      strokeColor: '#BB2299',
      strokeWeight: 6,
      showDir: Boolean(showDir),
      lineJoin: 'round'
    });
  } else {
    // 根据gps点位画线
    pathArr.forEach((item: any, index: number) => {
      new window.AMap.Polyline({
        map: mapRef.current,
        path: item,
        strokeColor: index % 2 === 0 ? '#BB2299' : '#04f1fc',
        strokeWeight: 6,
        showDir: Boolean(showDir),
        lineJoin: 'round'
      });
    });
  }

  // 起始位置的icon
  const startIcon = new window.AMap.Icon({
    size: new window.AMap.Size(25, 34),
    image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
    imageSize: new window.AMap.Size(135, 40),
    imageOffset: new window.AMap.Pixel(-9, -3)
  });
  // 终止位置的icon
  const endIcon = new window.AMap.Icon({
    size: new window.AMap.Size(25, 34),
    image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
    imageSize: new window.AMap.Size(135, 40),
    imageOffset: new window.AMap.Pixel(-95, -3)
  });

  // 渲染开始marker
  const startPoint = points[0];
  const startPointMarker = new window.AMap.Marker({
    position: startPoint.lnglat,
    icon: startIcon,
    zIndex: 121,
    offset: new window.AMap.Pixel(-10, -30)
  });
  mapRef.current.add(startPointMarker);
  startPointMarker.originData = startPoint.originData;
  startPointMarker.on('click', async(point: any) => {
    const address = await _getAddress(point?.target?.originData);
    showInfoWindow(point, mapRef, false, address)
  });

  // 渲染结束marker
  const endPoint = points[points.length - 1];
  const endPointMarker = new window.AMap.Marker({
    position: endPoint.lnglat,
    icon: endIcon,
    zIndex: 121,
    offset: new window.AMap.Pixel(-10, -30)
  });
  mapRef.current.add(endPointMarker);
  endPointMarker.originData = startPoint.originData;
  endPointMarker.on('click', async(point: any) => {
    const address = await _getAddress(point?.target?.originData);
    showInfoWindow(point, mapRef, false, address)
  });

  points.splice(0, 1);
  points.splice(points.length - 1, 1);
  // 使用海量点渲染途径gps点
  const mass = new window.AMap.MassMarks(points, {
    opacity: 1,
    zIndex: 121,
    cursor: 'pointer',
    anchor: new window.AMap.Pixel(-6, -6),
    style: [
      {
        url: 'https://webapi.amap.com/images/mass/mass0.png',
        anchor: new window.AMap.Pixel(6, 6),
        size: new window.AMap.Size(11, 11),
        zIndex: 3
      }
    ]
  });
  mass.on('click', async(e: any) => {
    e.target.originData = e.data?.originData;
    e.lnglat = e.data?.lnglat;
    const address = await _getAddress(e?.target?.originData);
    showInfoWindow(e, mapRef, false, address);
  });
  mass.setMap(mapRef.current);
};

/**
 * 地图上显示报警信息或车身信息或记录仪信息的marker
 * @param data 数据
 * @param clipPosition 使用marker图片的裁剪位置
 * @param mapRef 地图实例
 * @param alarmLabelsRef 存储报警label
 */
export const renderMarker = (
  data: any,
  clipPosition: number,
  mapRef: any,
  alarmLabelsRef?: any
) => {
  // 用于承载LabelMarker的图层
  const labelsLayer = new window.AMap.LabelsLayer({
    collision: false,
    allowCollision: false,
    zooms: [0, 20]
  });
  data.forEach((item: any) => {
    const transformGcoord = gcoord.transform(
      [item.lat, item.lon],
      gcoord.WGS84,
      gcoord.AMap
    );
    const lnglat = new window.AMap.LngLat(
      transformGcoord[0],
      transformGcoord[1]
    );
    // 创建一个 LabelMarker 实例
    const alarmMarker = new window.AMap.LabelMarker({
      position: lnglat,
      icon: {
        image:
          'https://webapi.amap.com/ui/1.1/ui/overlay/SimpleMarker/assets/default/default-fs8.png',
        anchor: 'bottom-center',
        size: [25, 34],
        clipOrigin: [clipPosition, 0],
        clipSize: [36, 46]
      }
    });
    alarmMarker.originData = item;
    alarmMarker.on('click', (point: any) => showInfoWindow(point, mapRef));
    // 将 LabelMarker 实例添加到 LabelsLayer 上
    labelsLayer.add(alarmMarker);
    if (alarmLabelsRef) {
      const {
        type = 'alarm',
        alarmType,
        vehicleSpeed,
        gpsSpeed,
        reportedTime,
        info
      } = item;
      // 存储报警信息文本标注，勾选信息时显示
      const labelString = `<div class="mapAlarmLable">报警类型: ${renderEventType(
        {
          type,
          alarmType,
          info
        }
      )}
        ${
          vehicleSpeed === null
            ? ''
            : `<br>车身速度: ${ownRound(vehicleSpeed, 1)}`
        }
        ${gpsSpeed === null ? '' : `<br>gps速度: ${formatGpsSpeed(gpsSpeed)}`}
        <br/>时间: ${formatTime(reportedTime)}</div>`;
      // 创建文本标注
      const label = new window.AMap.Text({
        position: lnglat,
        content: labelString,
        offset: new window.AMap.Pixel(-10, -10),
        zIndex: 123,
        topWhenClick: true
      });
      if (type === 'alarm') {
        switch (alarmType) {
          case 1:
            alarmLabelsRef.current[1].push(label);
            break;
          case 2:
            alarmLabelsRef.current[2].push(label);
            break;
          case 3:
            alarmLabelsRef.current[3].push(label);
            break;
          case 4:
            alarmLabelsRef.current[4].push(label);
            break;
          case 5:
            alarmLabelsRef.current[5].push(label);
            break;
          default:
            alarmLabelsRef.current[6].push(label);
        }
      }
      if (type === 'alarm808') {
        alarmLabelsRef.current[7].push(label);
      }
    }
  });
  // 将 LabelsLayer 添加到地图上
  mapRef.current.add(labelsLayer);
};

// 地图上设置车辆的位置(在地图中心显示)
export const setCarLocation = async (
  id: string,
  mapRef: any,
  isOnline?: boolean
) => {
  mapRef.current.clearMap();
  const res = await currentLocationRequest(id);
  const locationData = res?.data?.data || {};
  if (locationData.lon && locationData.lat) {
    const transformGcoord = gcoord.transform(
      [locationData.lat, locationData.lon],
      gcoord.WGS84,
      gcoord.AMap
    );
    const lnglat = new window.AMap.LngLat(
      transformGcoord[0],
      transformGcoord[1]
    );
    const icon = new window.AMap.Icon({
      image: isOnline ? onlineCarIcon : carIcon,
      imageSize: [32, 32]
    });
    const marker = new window.AMap.Marker({
      position: lnglat,
      offset: new window.AMap.Pixel(-10, -10),
      icon
    });
    mapRef.current.setCenter(lnglat);
    mapRef.current.add(marker);
  }
};

// 行车记录仪视频的获取状态
export const renderDvrVideoStatus = (value?: number) => {
  if (isNil(value)) {
    return '获取指令已下发(稍后可刷新视频列表查看)';
  } else if (value === 0) {
    return '视频可播放';
  } else {
    return driveVideoErrorMessage[value] || value;
  }
};