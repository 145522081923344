// extracted by mini-css-extract-plugin
export default {"carDiagnose":"carDiagnose_carDiagnose__YB3YB","searchBox":"carDiagnose_searchBox__6LwgF","carInfoBox":"carDiagnose_carInfoBox__uJ8kt","carInfoTitle":"carDiagnose_carInfoTitle__Dftd5","carInfoContent":"carDiagnose_carInfoContent__IdTG0","carInfoPerson":"carDiagnose_carInfoPerson__A87ka","carInfoDriver":"carDiagnose_carInfoDriver__2imRI","personPhone":"carDiagnose_personPhone__s3Y-Y","driverPhone":"carDiagnose_driverPhone__l7oqg","carMap":"carDiagnose_carMap__j1m2U"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"carDiagnose\":\"carDiagnose_carDiagnose__YB3YB\",\"searchBox\":\"carDiagnose_searchBox__6LwgF\",\"carInfoBox\":\"carDiagnose_carInfoBox__uJ8kt\",\"carInfoTitle\":\"carDiagnose_carInfoTitle__Dftd5\",\"carInfoContent\":\"carDiagnose_carInfoContent__IdTG0\",\"carInfoPerson\":\"carDiagnose_carInfoPerson__A87ka\",\"carInfoDriver\":\"carDiagnose_carInfoDriver__2imRI\",\"personPhone\":\"carDiagnose_personPhone__s3Y-Y\",\"driverPhone\":\"carDiagnose_driverPhone__l7oqg\",\"carMap\":\"carDiagnose_carMap__j1m2U\"}";
        // 1728725595699
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  