import {
  AutoComplete,
  Button,
  Cascader,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  TimePicker,
} from "antd";
import { ModalProps } from "antd/lib/modal/Modal";
import { createContext, useEffect, useState } from "react";
import CarType from "@/pages/offerRecord/constant/carType";
import { tagListRequest } from "@/service/tagManage";
import {
  applyPremiumRequest,
  areaListRequest,
  carTypeListRequest,
  getConfigRequest,
  quoteRequest,
} from "@/service/offerRecord";
import {
  fullTypeGen,
  getChildTypeByParentCode,
  rootNodeFilter,
} from "@/pages/offerRecord/utils/utils";
import useVehicleType from "@/pages/offerRecord/utils/vehicleType.hook";
import TextArea from "antd/lib/input/TextArea";
import UploadImageVideo from "@/components/uploadImageVideo";
import { LoadingOutlined } from "@ant-design/icons";

interface QuotationModalProps extends ModalProps {
  open: boolean;
  onCancel: () => void;
}

function QuotationModal(props: QuotationModalProps) {
  const { open, onCancel } = props;
  const [form] = Form.useForm();
  const [fuelType, setFuelType] = useState<any>([]); // 燃料类型
  const { allVehicleType } = useVehicleType(); // 车辆类型

  const [isGetVehicleConfigEnable, setIsGetVehicleConfigEnable] =
    useState<boolean>(false);
  const [allVehicleConfig, setAllVehicleConfig] = useState<any>([]);
  const [selectedVehicleConfig, setSelectedVehicleConfig] = useState<any>(null);
  const [fileList, setFileList] = useState<any>([]);
  const [spinning, setSpinning] = useState(false);
  const [modal, contentHolder] = Modal.useModal()

  const ReachableContext = createContext<string | null>(null);

  const IdCardType = [
    { label: "身份证", value: 1 },
    { label: "组织机构代码证", value: 2 },
    { label: "港澳居民来往内地通行证", value: 5 },
    { label: "营业执照", value: 9 },
    { label: "港澳身份证", value: 14 },
  ];
  const [allCities, setAllCities] = useState<any>([]); // 城市

  //附件上传组件的回调
  const updateFileList = (item: any) => {
    setFileList(item);
    form.validateFields(["attaches"]);
    form.setFieldValue("attaches", item);
  };

  const closeCallback = () => {
    form.resetFields();
    onCancel();
  };

  //获取燃料类型
  const getFuelType = async () => {
    const res = await tagListRequest({ tagSorts: [54] });
    const resData = res.data?.data || [];
    return resData.map((item: any) => {
      return { label: item.title, value: item.title };
    });
  };

  //获取城市
  const getAllCities = async () => {
    const res = await areaListRequest();
    const resData = res.data?.data || [];
    return resData.filter(rootNodeFilter).map(fullTypeGen(resData));
  };

  useEffect(() => {
    //获取燃料类型
    getFuelType().then((res) => setFuelType(res));

    getAllCities().then((res) => setAllCities(res));
  }, []);

  //获取配置信息
  const getVehicleConfig = async () => {
    const { LicenseNo, CarVin, EngineNo, MoldName } = form.getFieldsValue();

    const params = {
      LicenseNo,
      CarVin,
      EngineNo,
      MoldName,
      CityCode: 1,
    };

    getConfigRequest(params)
      .then((res) => {
        const resData = res.data?.data || [];
        console.log(resData);
        const configList = resData.map((item: any) => {
          return {
            label: item.VehicleAlias + " (" + item.PurchasePrice + "元)",
            value: JSON.stringify({
              AutoMoldCode: item.VehicleNo,
              PurchasePrice: item.PurchasePrice,
            }),
          };
        });
        setAllVehicleConfig(configList || []);
      })
      .catch(() => {
        setAllVehicleConfig([]);
      });
  };

  //选择配置之后的回调
  const onSelectConfig = (value: string) => {
    const config = JSON.parse(value);
    const price = config.PurchasePrice;
    const NegotiatePrice = getDepreciation({
      RegisterDate: form.getFieldValue("RegisterDate"),
      PurchasePrice: price,
    });
    //设置车辆价格
    form.setFieldsValue({
      NegotiatePrice,
    });
  };

  const getDepreciation = ({ RegisterDate, PurchasePrice }: any) => {
    const today = new Date();
    const registerDate = new Date(RegisterDate);
    const yearDiff = today.getFullYear() - registerDate.getFullYear();
    const monthDiff = today.getMonth() - registerDate.getMonth();
    const totalMonth = yearDiff * 12 + monthDiff;
    let s = totalMonth * 0.009; // 折旧值
    if (s > 0.8) {
      s = 0.8;
    }
    return Number((PurchasePrice * (1 - s) * 0.6).toFixed(2));
  };

  //提交
  const onOk = () => {
    form.validateFields().then(() => {
      const params = {
        ...form.getFieldsValue(),
      };
      //处理城市只选择市一级别的code
      if (params.CityCode?.[1]) {
        params.CityCode = params.CityCode?.[1];
      }
      if (params.RegisterDate) {
        params.RegisterDate = params.RegisterDate.format("YYYY-MM-DD");
      }
      if (params.bizTimeStampTime) {
        const time = params.bizTimeStampTime.valueOf();
        params.BizTimeStamp = Math.floor(time / 1000);
        delete params.bizTimeStampTime;
      }
      if (params["config-info"]) {
        const info = JSON.parse(params["config-info"]);
        params.AutoMoldCode = info.AutoMoldCode;
        params.PurchasePrice = info.PurchasePrice;
        delete params["config-info"];
      }
      if (params?.VehicleType?.[1]) {
        const parentNode = allVehicleType.find(
          (item: any) => item.value === params.VehicleType[0]
        );
        if (parentNode?.children?.length) {
          const currentNode = parentNode.children.find(
            (item: any) => item.value === params.VehicleType[1]
          );
          if (currentNode) {
            params.VehicleType = currentNode.label;
          }
        }
      } else {
        delete params.VehicleType;
      }
      if (params.fuelType === "新能源/电") {
        params.newEnergyVehicleFlag = 1;
      } else {
        params.newEnergyVehicleFlag = 0;
      }
      params.QuoteParalelConflict = 1;
      params.QuoteGroup = 1;
      params.SubmitGroup = 1;

      params.deviceType = "";
      params.HuaHen = 0;
      params.ForceTax = 0;
      // 计算车损
      // const NegotiatePrice = getDepreciation(params);
      // console.log(NegotiatePrice, 'Number(NegotiatePrice)Number(NegotiatePrice)')
      if (params.NegotiatePrice > 0) {
        params.CheSun = 1;
      } else {
        params.CheSun = 0;
      }
      setSpinning(true);
      //发送报价请求
      applyPremiumRequest(params)
        .then((res) => {
          if (res.data.code) {
            setSpinning(false);
            // message.warning(res.data.msg);
           modal.error({
              title: '报价失败',
              content: (
                <>
                 {res.data.msg}
                </>
              )
            })
            
          } else {
            const id = res.data.data;
            if (id) {
              params.applyId = id;
            }
            //报价
            quoteRequest(params)
              .then((res) => {
                if (res.data.code) {
                  // message.warning(res.data.msg)
                } else {
                  message.success("报价成功");
                  closeCallback();
                  setSpinning(false);
                }
              })
              .catch(() => {
                message.error("报价失败");
                setSpinning(false);
              });
          }
        })
        .catch(() => {
          message.error("报价失败");
          setSpinning(false);
        });
    });
  };

  const updateInsure = (type: string) => {
    if (type === "insure") {
      form.setFieldValue("InsuredName", form.getFieldValue("CarOwnersName"));
      form.setFieldValue("InsuredMobile", form.getFieldValue("Mobile"));
      form.setFieldValue(
        "InsuredIdType",
        form.getFieldValue("OwnerIdCardType")
      );
      form.setFieldValue("InsuredIdCard", form.getFieldValue("IdCard"));
    }
    if (type === "holder") {
      form.setFieldValue("HolderName", form.getFieldValue("CarOwnersName"));
      form.setFieldValue("HolderMobile", form.getFieldValue("Mobile"));
      form.setFieldValue("HolderIdType", form.getFieldValue("OwnerIdCardType"));
      form.setFieldValue("HolderIdCard", form.getFieldValue("IdCard"));
    }
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    setIsGetVehicleConfigEnable(
      !!(
        allValues.LicenseNo &&
        allValues.CarVin &&
        allValues.EngineNo &&
        allValues.MoldName
      )
    );
  };

  return (
    <Modal
      open={open}
      title={"创建报价"}
      maskClosable={false}
      onOk={onOk}
      onCancel={onCancel}
      width={"1200px"}
    >
      <ReachableContext.Provider value="">
      <div
        style={{
          maxHeight: "65vh",
          overflowY: "auto",
          overflowX: "hidden",
          paddingRight: "16px",
        }}
      >
        <Form form={form} onValuesChange={onValuesChange}>
          <Divider style={{ marginTop: "0" }} orientation="left" >
            基础信息
          </Divider>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item
                labelCol={{ span: 10 }}
                label={"报价所属公司"}
                name="applyCompany"
                required
              >
                <Input placeholder={"请输入所属分公司"}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                labelCol={{ span: 10 }}
                label={"报价申请人"}
                name="applyUser"
                required
              >
                <Input placeholder={"请输入报价申请人"}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                labelCol={{ span: 10 }}
                name="LicenseNo"
                label={"车牌号"}
                required
              >
                <Input placeholder={"请输入车牌号"}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                labelCol={{ span: 10 }}
                name="bizTimeStampTime"
                label={"投保日期"}
                required
              >
                <DatePicker
                  placeholder={"请选择投保日期"}
                  style={{ width: "100%" }}
                ></DatePicker>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left" style={{ marginTop: "0" }}>
            车辆信息
          </Divider>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                label={"发动机号码"}
                name="EngineNo"
              >
                <Input placeholder={"请输入发动机号码"}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name="CarVin"
                label={"车辆识别代号"}
              >
                <Input placeholder={"请输入车辆识别代号"}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                name="MoldName"
                labelCol={{ span: 10 }}
                label={"品牌型号"}
              >
                <Input placeholder={"请输入品牌型号"}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                name={"CarType"}
                labelCol={{ span: 10 }}
                label={"车型"}
              >
                <Select
                  options={CarType}
                  placeholder={"请选择车型"}
                  allowClear={true}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name={"SeatCount"}
                label={"核定载客量"}
              >
                <Input placeholder={"请输入核定载客量"}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                label={"是否分期"}
                name={"instalment"}
              >
                <Radio.Group>
                  <Radio value="1">是</Radio>
                  <Radio value="0">否</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name={"fuelType"}
                label={"燃料类型"}
              >
                <Select
                  options={fuelType}
                  placeholder={"请选择燃料类型"}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name={"VehicleType"}
                label={"车辆类型"}
              >
                <Cascader
                  options={allVehicleType}
                  placeholder={"请选择车辆类型"}
                ></Cascader>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name={"TonCount"}
                label={"载重量(单位:kg)"}
              >
                <Input placeholder={"请输入载重量"}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name={"TractionTonCount"}
                label={"牵引力(单位:牛)"}
              >
                <Input placeholder={"请输入牵引力"}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name={"RegisterDate"}
                label={"注册日期"}
              >
                <DatePicker
                  placeholder={"请选择注册日期"}
                  style={{ width: "100%" }}
                ></DatePicker>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name={"CityCode"}
                label={"选择城市"}
              >
                <Cascader
                  options={allCities}
                  placeholder={"请选择城市"}
                ></Cascader>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                labelCol={{ span: 10 }}
                label={"配置信息"}
                name={"config-info"}
              >
                <div style={{ display: "flex" }}>
                  <Select
                    options={allVehicleConfig}
                    placeholder={"请选择配置信息"}
                    allowClear
                    onSelect={onSelectConfig}
                    style={{ paddingBottom: "16px" }}
                  ></Select>
                  <Button onClick={getVehicleConfig}>获取配置信息</Button>
                </div>
              </Form.Item>
              {/* <Row style={{marginTop: '-24px'}}>
                <Col span={14} offset={10}>
                  <Button disabled={!isGetVehicleConfigEnable} onClick={getVehicleConfig}>获取配置信息</Button>
                </Col>
              </Row> */}
            </Col>
          </Row>
          <Divider style={{ marginTop: "0" }} orientation="left">
            车主信息
          </Divider>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item
                required
                name="CarOwnersName"
                labelCol={{ span: 10 }}
                label={"车主姓名"}
              >
                <Input placeholder={"请输入车主姓名"}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                name="Mobile"
                labelCol={{ span: 10 }}
                label={"车主手机号"}
              >
                <Input placeholder={"请输入手机号"}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                name="OwnerIdCardType"
                labelCol={{ span: 10 }}
                label={"证件类型"}
              >
                <Select
                  options={IdCardType}
                  placeholder={"请选择证件类型"}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                name="IdCard"
                labelCol={{ span: 10 }}
                label={"证件号码"}
              >
                <Input placeholder={"请输入证件号码"}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                name="OwnerAddress"
                labelCol={{ span: 10 }}
                label={"车主地址"}
              >
                <Input placeholder={"请输入车主地址"}></Input>
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ marginTop: "0" }} orientation="left">
            被保险人信息{" "}
            <Button
              onClick={() => {
                updateInsure("insure");
              }}
            >
              同车主
            </Button>{" "}
          </Divider>
          {/*<Row>*/}
          {/*  <Col span={8}>*/}
          {/*    <Form.Item labelCol={{span: 10}} label={"点击被保险人信息同上"}>*/}
          {/*      <Switch></Switch>*/}
          {/*    </Form.Item>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name="InsuredName"
                label={"被保险人姓名"}
              >
                <Input placeholder={"请输入被保险人姓名"}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name="InsuredMobile"
                label={"被保险人手机号"}
              >
                <Input placeholder={"请输入被保险人手机号"}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name="InsuredIdType"
                label={"被保险人证件类型"}
              >
                <Select
                  options={IdCardType}
                  placeholder={"请选择被保险人证件类型"}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name="InsuredIdCard"
                label={"被保险人证件号码"}
              >
                <Input placeholder={"请输入被保险人证件号码"}></Input>
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ marginTop: "0" }} orientation="left">
            投保人信息{" "}
            <Button
              onClick={() => {
                updateInsure("holder");
              }}
            >
              同车主
            </Button>
          </Divider>
          {/*<Row>*/}
          {/*  <Col span={8}>*/}
          {/*    <Form.Item labelCol={{span: 10}} label={"点击投保人信息同上"}>*/}
          {/*      <Switch></Switch>*/}
          {/*    </Form.Item>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name="HolderName"
                label={"投保人姓名"}
              >
                <Input placeholder={"请输入投保人姓名"}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name="HolderMobile"
                label={"投保人手机号"}
              >
                <Input placeholder={"请输入投保人手机号"}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name="HolderIdType"
                label={"投保人证件类型"}
              >
                <Select
                  options={IdCardType}
                  placeholder={"请选择投保人证件类型"}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name="HolderIdCard"
                label={"投保人证件号码"}
              >
                <Input placeholder={"请输入投保人证件号码"}></Input>
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ marginTop: "0" }} orientation="left">
            保险信息
          </Divider>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name={"SiJi"}
                label={"车上人员责任险(司机)"}
              >
                <AutoComplete
                  options={[
                    { label: "0", value: 0 },
                    { label: "10000", value: 10000 },
                    { label: "50000", value: 50000 },
                    { label: "100000", value: 100000 },
                    { label: "200000", value: 200000 },
                    { label: "500000", value: 500000 },
                  ]}
                ></AutoComplete>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name={"ChengKe"}
                label={"车上人员责任险(乘客)"}
              >
                <AutoComplete
                  options={[
                    { label: "0", value: 0 },
                    { label: "10000", value: 10000 },
                    { label: "50000", value: 50000 },
                    { label: "100000", value: 100000 },
                    { label: "200000", value: 200000 },
                    { label: "500000", value: 500000 },
                  ]}
                ></AutoComplete>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name={"SanZhe"}
                label={"第三者责任保险"}
              >
                <Input placeholder={"请输入第三者责任保险"}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                labelCol={{ span: 10 }}
                name={"NegotiatePrice"}
                label={"机动车损失保险"}
              >
                <Input placeholder={"请输入机动车损失保险"}></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={"remark"} labelCol={{ span: 10 }} label={"备注"}>
                <TextArea placeholder={"请输入备注"}></TextArea>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={"附件"}
                labelCol={{ span: 10 }}
                shouldUpdate={true}
                required
                name="attaches"
                rules={[
                  {
                    required: true,
                    validator(rule, value, callback) {
                      setTimeout(() => {
                        if (fileList.length > 0) {
                          callback();
                        } else {
                          callback("请选择附件");
                        }
                      }, 10);
                    },
                  },
                ]}
              >
                <UploadImageVideo
                  businessName={"上传附件"}
                  defaultUploadFiles={fileList}
                  uploadFilesChange={updateFileList}
                  canUploadVideo={false}
                  limitSize={10}
                  maxCount={20}
                  multiple={true}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {spinning && (
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(0,0,0,0.5)",
              zIndex: 1
            }}
          >
            {spinning && (
              <Spin indicator={<LoadingOutlined spin />} size="large" />
            )}
          </div>
        )}
        
      </div>
      {contentHolder}
      </ReachableContext.Provider>
    </Modal>
  );
}

export default QuotationModal;
