/**
 * 车辆管理相关请求
 */
import request from "@/utils/request";

export interface CarListRequestParams {
  page: number;
  limit: number;
  sn?: string;
  plate_number?: string;
  cate_id?: number | Array<number>;
  installCaptain?: string;
  polygon?: Array<number>;
  carId?:any;
}

// 获取车辆列表数据
export const carListRequestPath = '/api/v2/car/list';
export const carListRequest = (data: CarListRequestParams) => {
  return request.post(carListRequestPath, data)
}

//获取车辆位置数据（来源是abe设备或者是adas设备）
export const carLastLocationRequestPath = '/api/v2/car/location'
export const carLastLocationRequest = (data: any) => {
  return request.get(carLastLocationRequestPath, {params: data})
}

// 获取车辆列表数据
export const carListRequestPaths = '/api/v2/car/v1/list';
export const carListRequests = (data:any) => {
  return request.post(carListRequestPaths, data)
}

// 添加或编辑车辆建群
export const upsertCarRequest = (data: any) => {
  return request.post('/api/v2/car/upsert', data)
}

// 添加或编辑车辆建群
export const upsertCarRequests = (data: any) => {
  return request.post('/api/v2/car/v1/upsert', data)
}

// 添加或编辑运维备注
export const upsertOperationRequest = (data: any) => {
  return request.post('/api/v2/car/upsetOperateContent', data)
}

//// 更新设备状态（aeb ，adas）
export const updateServiceStateRequest = (data: any) => {
  return request.post('/api/v2/car/updateServiceState', data)
}

// 添加或编辑车辆电话
export const updateContactCarRequest = (data: any) => {
  return request.post('/api/v2/car/updateContact', data)
}

// 添加或编辑车辆状态
export const updateVehicleStateRequest = (data: any) => {
  return request.post('api/v2/car/updateVehicleState', data)
}



// 添加或编辑行驶证合同
export const drivingLicenseContractRequest = (data: any) => {
  return request.post('/api/v2/car/modify', data)
}

// 获取车辆详情
export const carDetailRequest = (id: string | number) => {
  return request.get(`/api/v2/car/detail/${id}`)
}

// 车辆解绑设备
export const unbindDeviceRequest = (data: { carId: number, deviceId?: number }) => {
  return request.put('/api/v2/car/unbind', data)
}

// 获取车辆信息(根据车牌号或者设备SN或者车架号)
export const findOneCarRequest = (params: { plateNumber?: string; sn?: string; frameNumber?: string; }) => {
  return request.get('/api/v2/car/findOne', { params })
}

// 获取车辆品牌列表
export const carBrandRequest = () => {
  return request.get('/api/v2/car/brands')
}

// 获取车辆车型列表
export const carModelRequest = () => {
  return request.get('/api/v2/car/models')
}

// 车辆批量导入的请求路径
export const carImportRequestPath = '/api/v2/car/import';


// 添加或编辑运维状态以及备注
export const upsetOperateContentRequests = (data: any) => {
  return request.post('/api/v2/car/upsetOperateContent', data)
}

// 添加或编辑用户配合度
export const updateVehicleCoordinateAbilityRequests = (data: any) => {
  return request.post('/api/v2/car/updateVehicleCoordinateAbility', data)
}