// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","realTimeMonitor":"realTimeMonitor_realTimeMonitor__y1HIU","carTreeWrapper":"realTimeMonitor_carTreeWrapper__OitbZ","monitorContent":"realTimeMonitor_monitorContent__D2ebJ","videoMapMonitor":"realTimeMonitor_videoMapMonitor__jM1Q5","videoMonitorWrapper":"realTimeMonitor_videoMonitorWrapper__7Ezrd","switchVideoLayout":"realTimeMonitor_switchVideoLayout__T0OM2","blockItem":"realTimeMonitor_blockItem__R17ki","blockChildrenItem":"realTimeMonitor_blockChildrenItem__mAy-V","videoMonitor":"realTimeMonitor_videoMonitor__FrSzL"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"realTimeMonitor\":\"realTimeMonitor_realTimeMonitor__y1HIU\",\"carTreeWrapper\":\"realTimeMonitor_carTreeWrapper__OitbZ\",\"monitorContent\":\"realTimeMonitor_monitorContent__D2ebJ\",\"videoMapMonitor\":\"realTimeMonitor_videoMapMonitor__jM1Q5\",\"videoMonitorWrapper\":\"realTimeMonitor_videoMonitorWrapper__7Ezrd\",\"switchVideoLayout\":\"realTimeMonitor_switchVideoLayout__T0OM2\",\"blockItem\":\"realTimeMonitor_blockItem__R17ki\",\"blockChildrenItem\":\"realTimeMonitor_blockChildrenItem__mAy-V\",\"videoMonitor\":\"realTimeMonitor_videoMonitor__FrSzL\"}";
        // 1728725595240
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  