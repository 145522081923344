/**
 * 合同管理
 */
import React, {useEffect, useState} from 'react';
import {Card, Form, Row, Col, Input, Button, message, Select, Modal, Popconfirm} from 'antd';
import styles from '@/styles/pageContent.module.scss';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import PdfPreviewModal from '@/components/pdfPreviewModal';
import {useTable} from '@/utils/useTable';
import {
  DeleteContract,
  contractDetailRequest,
  contractListRequest,
  contractListRequestPath,
  importContractRequestPath
} from '@/service/contractManage'
import OperateModal from './components/operateModal';
import dayjs from 'dayjs';
// 英文日期转换
import 'moment/locale/zh-cn';
import JudicialRecordModal from './components/judicialRecordModal';
import {caseFlagDataa, modeData, stateData} from '@/constants/underwritingRecord';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import {DownloadOutlined, FileZipOutlined, EyeOutlined} from '@ant-design/icons';
import {downloadFile} from '@/utils/download';
import ExportButton from '@/components/exportButton';
import UploadExcel from '@/components/uploadExcel';
import DatePicker from '@/components/datePicker';
import {tagListRequest} from '@/service/tagManage';
import {TagCategoryEnum} from '@/constants/tagManage';

const {RangePicker} = DatePicker;
const ClientFile = () => {
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息
  const [operateInfoCopy, setOperateInfoCopy] = useState<any>({}); // 操作拷贝的信息
  const [pdfPreviewInfo, setPdfPreviewInfo] = useState<any>(null); // PDF合同预览的信息
  const [form] = Form.useForm();
  const [copyModal, setCopyModal] = useState<any>(null); // 操作拷贝的信息
  const [LawsuitState, setLawsuitState] = useState<any>([]); // 诉讼记录详情

  useEffect(() => {
    getFuelType()
  }, [])

  const {page, limit, loading, data, totalCount, onPageChange, getData} = useTable({
    requestFunc: contractListRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });
  // 处理搜索参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newParams = {...params};
    if (newParams.beginTime) {
      newParams.beginTimeBegin = dayjs(params.beginTime[0]).format('YYYY-MM-DD')
      newParams.beginTimeEnd = dayjs(params.beginTime[1]).format('YYYY-MM-DD')
      delete newParams.beginTime;
    }
    if (newParams.endTime) {
      newParams.endTimeBegin = dayjs(params.endTime[0]).format('YYYY-MM-DD')
      newParams.endTimeEnd = dayjs(params.endTime[1]).format('YYYY-MM-DD')
      delete newParams.endTime;
    }
    if (newParams.installTime) {

      newParams.installTime = dayjs(params.installTime).format('YYYY-MM-DD');
    }
    if (newParams.uninstallTime) {
      newParams.uninstallTime = dayjs(params.uninstallTime).format('YYYY-MM-DD');
    }
    if (isExport) {
      newParams.export = true;
    }

    return newParams;
  }

  const getFuelType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.lawsuitState],
    });
    const tags: any = [];
    (res.data?.data || []).map((item: any) => {
      const {title} = item;
      if (title === '已转交法务') {
        tags.unshift({label: title, value: title})
      } else {
        tags.push({label: title, value: title});
      }

    });
    setLawsuitState(tags);
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  }

  // PDF合同预览
  const onPdfPreview = (url: string, fileName: string) => {
    setPdfPreviewInfo({
      url,
      fileName,
    });
  }

  // 删除
  const deleteContract = async (id: number) => {
    const res = await DeleteContract(id)
    if (res.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  }

  // 上传成功的方法
  const uploadSuccess = (resData: any) => {
    if (typeof resData === 'boolean') {
      message.success('导入成功');
      getData();
    }
  }

  // const getCopyItem = (item:any) => {
  //   setOperateInfo({ type: 'copy', data: item})
  // }

  const submit = async (values: any) => {
    setCopyModal(null)
    try {
      const params = {...values};
      const res = await contractDetailRequest(params);
      if (res.data?.code === 0) {
        setOperateInfo({type: 'copy', data: res.data?.data})
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '合同号',
      dataIndex: 'no',
      width: 110,
      ellipsis: {showTitle: false},
    },
    {
      title: '合作模式',
      dataIndex: 'mode',
      width: 110,
      ellipsis: {showTitle: false},
    },
    {
      title: '合同状态',
      dataIndex: 'state',
      width: 110,
      ellipsis: {showTitle: false},
    },
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
      ellipsis: {showTitle: false},
    },
    {
      title: '车架号',
      dataIndex: 'frameNumber',
      width: 110,
      ellipsis: {showTitle: false},
    },
    {
      title: '甲方',
      dataIndex: 'mainA',
      width: 110,
      ellipsis: {showTitle: false},
    },
    {
      title: '乙方',
      dataIndex: 'mainB',
      width: 110,
      ellipsis: {showTitle: false},
    },
    {
      title: '是否诉讼',
      dataIndex: 'caseFlag',
      width: 110,
      ellipsis: {showTitle: false},
      render: (value: any) => value == 1 ? '有 ' : ' 无'
    },
    {
      title: '诉讼阶段',
      dataIndex: 'caseStage',
      width: 110,
      ellipsis: {showTitle: false},
    },
    {
      title: '续保年数',
      dataIndex: 'count',
      width: 110,
      ellipsis: {showTitle: false},
    },
    {
      title: '合同开始日期',
      dataIndex: 'beginTime',
      width: 110,
      ellipsis: {showTitle: false},
      render: (value: string) => value ? <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD')}/> : ''
    },
    {
      title: '合同结束日期',
      dataIndex: 'endTime',
      width: 110,
      ellipsis: {showTitle: false},
      render: (value: string) => value ? <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD')}/> : ''

    },
    {
      title: '设备安装时间',
      dataIndex: 'installTime',
      width: 110,
      ellipsis: {showTitle: false},
      render: (value: string) => value ? <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD')}/> : ''

    },
    {
      title: '设备安装人员',
      dataIndex: 'installer',
      width: 110,
      ellipsis: {showTitle: false},

    },
    {
      title: '设备拆除时间',
      dataIndex: 'uninstallTime',
      width: 110,
      ellipsis: {showTitle: false},
      render: (value: string) => value ? <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD')}/> : ''

    },
    {
      title: '设备拆除人员',
      dataIndex: 'removalUser',
      width: 110,
      ellipsis: {showTitle: false},
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 110,
      ellipsis: {showTitle: false},
    },
    {
      title: '附件',
      dataIndex: 'attachments',
      width: 280,
      ellipsis: {showTitle: false},
      render: (value: any) => (
        value?.map((item: any, index: any) => (
          <div key={index}
               style={{
                 border: '1px solid rgb(217,217,217)',
                 padding: '5px',
                 display: 'flex',
                 justifyItems: 'flex-start',
                 alignItems: 'center',
               }}>
            <div style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '80%',
              display: 'flex',
              alignItems: 'center',
            }}>
              <FileZipOutlined style={{paddingRight: '5px'}}/>
              <div style={{cursor: "pointer"}}>
                <RenderEllipsisColumn text={item.fileName}/>
              </div>

            </div>
            <div style={{
              width: '20%',
            }}>
              <Button type="text" onClick={() => onPdfPreview(item.url, item.fileName)} style={{padding: '4px'}}>
                <EyeOutlined/>
              </Button>
              <Button type="link" onClick={() => downloadFile(item.url, item.fileName)} style={{padding: '4px'}}>
                <DownloadOutlined/>
              </Button>
            </div>
          </div>
        ))
      ),
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 160,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType='/car-admin/contract-manage/edit'
              type="link"
              onClick={() => setOperateInfo({type: 'edit', data: item})}
            >
              编辑
            </PermissionButton>
            <Popconfirm
              title="您确定要删除该合同吗?"
              onConfirm={() => deleteContract(item.id)}
            >
              <PermissionButton
                operateType='/car-admin/contract-manage/delete'
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
            <PermissionButton
              operateType='/car-admin/contract-manage/record'
              type="link"
              onClick={() => setOperateInfo({type: 'judicialRecord', data: item})}
            >
              诉讼记录
            </PermissionButton>
            {/*<Button type="link" onClick={() => deleteContract(item.id)}>删除</Button>*/}
            {/*<Button type="link" onClick={() => setOperateInfo({type: 'judicialRecord', data: item})}>诉讼记录</Button>*/}
          </div>
        )
      },
    },
  ]
  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form} layout="vertical" autoComplete="off">
        <Row gutter={24}>
          <Col span={4}>
            <Form.Item label="合同号" name="no">
              <Input placeholder='请输入合同号' allowClear/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="车牌号" name="plateNumber">
              <Input placeholder='请输入车牌号' allowClear onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="车架号" name="frameNumber">
              <Input placeholder='请输入车架号' allowClear onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('frameNumber', newV)}}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="是否存在诉讼" name="caseFlag">
              <Select
                placeholder="请选择是否存在诉讼"
                options={caseFlagDataa}
                allowClear
                showArrow
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="诉讼阶段" name="caseStage">
              <Select
                placeholder="请选择诉讼阶段"
                options={LawsuitState}
                allowClear
                showArrow
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="合同状态" name="state">
              <Select
                placeholder="请选择合同状态"
                options={stateData}
                allowClear
                showArrow
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={4}>
            <Form.Item label="合作模式" name="mode">
              <Select
                placeholder="请选择承保模式"
                options={modeData}
                allowClear
                showArrow
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="合同开始时间" name="beginTime">
              <RangePicker allowClear style={{width: '100%'}}/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="合同结束时间" name="endTime">
              <RangePicker allowClear style={{width: '100%'}}/>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="&nbsp;">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton
                  url={contractListRequestPath}
                  fileName='合同管理'
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey='/car-admin/contract-manage/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <div className={styles.operateBox}>
            <PermissionButton
              operateType='/car-admin/contract-manage/add'
              type="primary"
              onClick={() => setOperateInfo({type: 'add'})}
            >
              添加
            </PermissionButton>
            <Button href="/contract.xlsx">下载导入模板文件</Button>
            <UploadExcel
              uploadRequestPath={importContractRequestPath}
              uploadPermissionKey="/car-admin/contrac-manage/import"
              uploadButtonProps={{children: '批量导入'}}
              uploadSuccessFunc={uploadSuccess}
            />
            <Button key="copy" onClick={() => {
              setCopyModal({type: 'copy'})
              form.setFieldsValue({no: null})
            }}>拷贝</Button>
          </div>
        </Row>
      </Form>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {/* 添加、编辑用户 */}
      {['add', 'edit', 'copy'].includes(operateInfo.type) &&
        <OperateModal
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
          type={operateInfo.type}
          data={operateInfo.data}
        />
      }
      {pdfPreviewInfo && <PdfPreviewModal
        title={pdfPreviewInfo?.fileName}
        pdfUrl={pdfPreviewInfo?.url}
        closeModal={() => setPdfPreviewInfo(null)}
      />}
      {/* 诉讼记录 */}
      {operateInfo.type === 'judicialRecord' &&
        <JudicialRecordModal item={operateInfo.data} onClose={() => {
          setOperateInfo({});
          form.submit()
        }}/>
      }
      {/* 拷贝 */}
      {/* {operateInfoCopy?.type === 'copy' &&
        <ContractCopyModal onCloseCopy={() => setOperateInfoCopy({})} reloadData={getData} copyItem={getCopyItem} visible={true}/>
      } */}
      {copyModal?.type && <Modal
        title="拷贝合同"
        visible
        centered
        onCancel={() => setCopyModal(null)}
        onOk={() => form.submit()}
        okButtonProps={{loading}}
        maskClosable={false}
      >
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          scrollToFirstError
          onFinish={submit}
        >
          <Form.Item
            label="合同号"
            name="no"
            rules={[
              {required: true, message: '请输入合同号'}
            ]}
          >
            <Input placeholder="请输入合同号" allowClear/>
          </Form.Item>
        </Form>
      </Modal>}
    </Card>
  )
}

export default ClientFile;


