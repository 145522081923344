// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","pageContentBox":"pageContent_pageContentBox__EddUI","fixPageContentBox":"pageContent_fixPageContentBox__YZaBM","fixPageTabContentBox":"pageContent_fixPageTabContentBox__BNfC+","fixModalTab":"pageContent_fixModalTab__Mkkt2","searchBox":"pageContent_searchBox__loVbh","operateBox":"pageContent_operateBox__9i0UL","tableTagColumn":"pageContent_tableTagColumn__qbNFV","operateTextButton":"pageContent_operateTextButton__vqWks","tableOperateColumn":"pageContent_tableOperateColumn__OutoY","scrollMoreTableFooter":"pageContent_scrollMoreTableFooter__x3MgH","desc":"pageContent_desc__mHXwi","spaceBetweenFlex":"pageContent_spaceBetweenFlex__MivNc","spaceBetweenPageContentBox":"pageContent_spaceBetweenPageContentBox__KTCEI","carTree":"pageContent_carTree__hwMZT","contentBox":"pageContent_contentBox__Ud7t1","searchButtonBox":"pageContent_searchButtonBox__AEeve","tipBox":"pageContent_tipBox__6G8E6","tableBox":"pageContent_tableBox__IfDqJ","drawerTableBox":"pageContent_drawerTableBox__JCu8C","commonTableWrapper":"pageContent_commonTableWrapper__xtqiu"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"pageContentBox\":\"pageContent_pageContentBox__EddUI\",\"fixPageContentBox\":\"pageContent_fixPageContentBox__YZaBM\",\"fixPageTabContentBox\":\"pageContent_fixPageTabContentBox__BNfC+\",\"fixModalTab\":\"pageContent_fixModalTab__Mkkt2\",\"searchBox\":\"pageContent_searchBox__loVbh\",\"operateBox\":\"pageContent_operateBox__9i0UL\",\"tableTagColumn\":\"pageContent_tableTagColumn__qbNFV\",\"operateTextButton\":\"pageContent_operateTextButton__vqWks\",\"tableOperateColumn\":\"pageContent_tableOperateColumn__OutoY\",\"scrollMoreTableFooter\":\"pageContent_scrollMoreTableFooter__x3MgH\",\"desc\":\"pageContent_desc__mHXwi\",\"spaceBetweenFlex\":\"pageContent_spaceBetweenFlex__MivNc\",\"spaceBetweenPageContentBox\":\"pageContent_spaceBetweenPageContentBox__KTCEI\",\"carTree\":\"pageContent_carTree__hwMZT\",\"contentBox\":\"pageContent_contentBox__Ud7t1\",\"searchButtonBox\":\"pageContent_searchButtonBox__AEeve\",\"tipBox\":\"pageContent_tipBox__6G8E6\",\"tableBox\":\"pageContent_tableBox__IfDqJ\",\"drawerTableBox\":\"pageContent_drawerTableBox__JCu8C\",\"commonTableWrapper\":\"pageContent_commonTableWrapper__xtqiu\"}";
        // 1728725593442
        var cssReload = require("../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  