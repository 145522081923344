// extracted by mini-css-extract-plugin
export default {"layoutHeader":"mainHeader_layoutHeader__FSjIF","checkBox":"mainHeader_checkBox__8KOge","logoTitle":"mainHeader_logoTitle__-IrLs","headerContent":"mainHeader_headerContent__xdfwE","toggleIcon":"mainHeader_toggleIcon__6zpvp","quickMenu":"mainHeader_quickMenu__x7UL0","userName":"mainHeader_userName__jqr90","informationText":"mainHeader_informationText__m4UiS","informationBox":"mainHeader_informationBox__2WUs7","information":"mainHeader_information__rDEtf","informationBoxReadAll":"mainHeader_informationBoxReadAll__wciyv","informationBoxItem":"mainHeader_informationBoxItem__Hgkbk","informationBoxCenter":"mainHeader_informationBoxCenter__P7yHI","informationBoxButton":"mainHeader_informationBoxButton__UdMMw","informationBoxButtonItem":"mainHeader_informationBoxButtonItem__3oEe4","messageCard":"mainHeader_messageCard__iXQ-b","informationNum":"mainHeader_informationNum__0bIFJ","menuBox":"mainHeader_menuBox__egU7J","menuGroup":"mainHeader_menuGroup__T0sFr","menuTitle":"mainHeader_menuTitle__1ilLQ","menuItemGroup":"mainHeader_menuItemGroup__hC0mc","menuItem":"mainHeader_menuItem__q-iHt"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"layoutHeader\":\"mainHeader_layoutHeader__FSjIF\",\"checkBox\":\"mainHeader_checkBox__8KOge\",\"logoTitle\":\"mainHeader_logoTitle__-IrLs\",\"headerContent\":\"mainHeader_headerContent__xdfwE\",\"toggleIcon\":\"mainHeader_toggleIcon__6zpvp\",\"quickMenu\":\"mainHeader_quickMenu__x7UL0\",\"userName\":\"mainHeader_userName__jqr90\",\"informationText\":\"mainHeader_informationText__m4UiS\",\"informationBox\":\"mainHeader_informationBox__2WUs7\",\"information\":\"mainHeader_information__rDEtf\",\"informationBoxReadAll\":\"mainHeader_informationBoxReadAll__wciyv\",\"informationBoxItem\":\"mainHeader_informationBoxItem__Hgkbk\",\"informationBoxCenter\":\"mainHeader_informationBoxCenter__P7yHI\",\"informationBoxButton\":\"mainHeader_informationBoxButton__UdMMw\",\"informationBoxButtonItem\":\"mainHeader_informationBoxButtonItem__3oEe4\",\"messageCard\":\"mainHeader_messageCard__iXQ-b\",\"informationNum\":\"mainHeader_informationNum__0bIFJ\",\"menuBox\":\"mainHeader_menuBox__egU7J\",\"menuGroup\":\"mainHeader_menuGroup__T0sFr\",\"menuTitle\":\"mainHeader_menuTitle__1ilLQ\",\"menuItemGroup\":\"mainHeader_menuItemGroup__hC0mc\",\"menuItem\":\"mainHeader_menuItem__q-iHt\"}";
        // 1728725594949
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  