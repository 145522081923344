// extracted by mini-css-extract-plugin
export default {"toggleBox":"alarmPush_toggleBox__iF1gY","icon":"alarmPush_icon__8GRd0","text":"alarmPush_text__VMx3C","count":"alarmPush_count__gt1+H","pushBox":"alarmPush_pushBox__mgUTJ","alarmBox":"alarmPush_alarmBox__abrNi","alarmItem":"alarmPush_alarmItem__D7ASY","title":"alarmPush_title__P+cV0","time":"alarmPush_time__uuKnc","clear":"alarmPush_clear__t3MUQ"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"toggleBox\":\"alarmPush_toggleBox__iF1gY\",\"icon\":\"alarmPush_icon__8GRd0\",\"text\":\"alarmPush_text__VMx3C\",\"count\":\"alarmPush_count__gt1+H\",\"pushBox\":\"alarmPush_pushBox__mgUTJ\",\"alarmBox\":\"alarmPush_alarmBox__abrNi\",\"alarmItem\":\"alarmPush_alarmItem__D7ASY\",\"title\":\"alarmPush_title__P+cV0\",\"time\":\"alarmPush_time__uuKnc\",\"clear\":\"alarmPush_clear__t3MUQ\"}";
        // 1728725590441
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  