// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","type":"jtDeviceAlarmCompare_type__Lk7BM","time":"jtDeviceAlarmCompare_time__1jvP4","operate":"jtDeviceAlarmCompare_operate__FWrLT","speed":"jtDeviceAlarmCompare_speed__GJLp9","evalute":"jtDeviceAlarmCompare_evalute__3wPTr","headerBlock":"jtDeviceAlarmCompare_headerBlock__tolSQ","leftHeader":"jtDeviceAlarmCompare_leftHeader__HScTu","rightHeader":"jtDeviceAlarmCompare_rightHeader__1Prj6","scrollHeader":"jtDeviceAlarmCompare_scrollHeader__2AJqr","headerGroupTitle":"jtDeviceAlarmCompare_headerGroupTitle__aekpa","headerTitle":"jtDeviceAlarmCompare_headerTitle__gUILX","title":"jtDeviceAlarmCompare_title__i2GN4","contentBlock":"jtDeviceAlarmCompare_contentBlock__PFIyh","alarmIntervalBlock":"jtDeviceAlarmCompare_alarmIntervalBlock__sa7qT","leftPart":"jtDeviceAlarmCompare_leftPart__FbcPg","rightPart":"jtDeviceAlarmCompare_rightPart__r49Er","tableRow":"jtDeviceAlarmCompare_tableRow__ljeJ8","tableCell":"jtDeviceAlarmCompare_tableCell__82K7G","formDeviceTitle":"jtDeviceAlarmCompare_formDeviceTitle__cFcbS","formDeviceBlock":"jtDeviceAlarmCompare_formDeviceBlock__36WNw"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"type\":\"jtDeviceAlarmCompare_type__Lk7BM\",\"time\":\"jtDeviceAlarmCompare_time__1jvP4\",\"operate\":\"jtDeviceAlarmCompare_operate__FWrLT\",\"speed\":\"jtDeviceAlarmCompare_speed__GJLp9\",\"evalute\":\"jtDeviceAlarmCompare_evalute__3wPTr\",\"headerBlock\":\"jtDeviceAlarmCompare_headerBlock__tolSQ\",\"leftHeader\":\"jtDeviceAlarmCompare_leftHeader__HScTu\",\"rightHeader\":\"jtDeviceAlarmCompare_rightHeader__1Prj6\",\"scrollHeader\":\"jtDeviceAlarmCompare_scrollHeader__2AJqr\",\"headerGroupTitle\":\"jtDeviceAlarmCompare_headerGroupTitle__aekpa\",\"headerTitle\":\"jtDeviceAlarmCompare_headerTitle__gUILX\",\"title\":\"jtDeviceAlarmCompare_title__i2GN4\",\"contentBlock\":\"jtDeviceAlarmCompare_contentBlock__PFIyh\",\"alarmIntervalBlock\":\"jtDeviceAlarmCompare_alarmIntervalBlock__sa7qT\",\"leftPart\":\"jtDeviceAlarmCompare_leftPart__FbcPg\",\"rightPart\":\"jtDeviceAlarmCompare_rightPart__r49Er\",\"tableRow\":\"jtDeviceAlarmCompare_tableRow__ljeJ8\",\"tableCell\":\"jtDeviceAlarmCompare_tableCell__82K7G\",\"formDeviceTitle\":\"jtDeviceAlarmCompare_formDeviceTitle__cFcbS\",\"formDeviceBlock\":\"jtDeviceAlarmCompare_formDeviceBlock__36WNw\"}";
        // 1728725595246
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  