/**

 * 车辆列表(Tree形式)

 */

import React, {
  useEffect,
  useState,
  useRef,
  ReactNode,
  useImperativeHandle,
  forwardRef,
} from "react";

import { useSelector, useDispatch } from "react-redux";

import { Input, Tree, Dropdown, Form } from "antd";

import {
  CarOutlined,
  VideoCameraOutlined,
  FilterOutlined,
} from "@ant-design/icons";

import { uniq, uniqBy } from "lodash";

import { filterMultipleCateData } from "@/utils/cate";

import { useDebounce } from "@/utils/ownHooks";

import { filterTreeData } from "@/utils/common";

import { initCarTreeData, removeCarTreeData } from "@/store/carTree/actions";

import LoadingWrapper from "@/components/loadingWrapper";

import styles from "./index.module.scss";

interface CarTreeProps {
  treeSelect?: (selectCarInfo: any) => void; // 选中时调用的方法

  treeCheck?: (carIds: Array<number>, carInfos?: any) => void; // 勾选复选框时调用的方法

  checkable?: boolean; // 是否显示复选框

  selectableTypes?: string[]; // 可以选择的数据类型(分组、车辆、通道)

  searchAlive?: boolean; // 搜索之后的勾选是否包含搜索前的数据

  extraTitle?: ReactNode; // 额外显示的标题

  showChannel?: boolean; // 是否显示部标机通道数据

  showFilter?: boolean; // 是否显示车辆过滤组件

  defaultFilterKey?: string; // 过滤组件默认选择的过滤key

  clickReplaceSelect?: boolean; // 组件默认逻辑为当已经selected的节点再次点击不会触发treeSelect方法，此属性可以实现只要点击节点就触发treeSelect方法

  searchPlateNumber?: string; // 搜索的车辆
}

const CarTree = (props: CarTreeProps, ref: any) => {
  const {
    treeSelect,

    treeCheck,

    checkable = false,

    selectableTypes = [],

    searchAlive = false,

    extraTitle,

    showChannel = false,

    showFilter = false,

    defaultFilterKey = "all",

    clickReplaceSelect = false,

    searchPlateNumber,
  } = props;

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const { userDetail } = useSelector((state: any) => state.userStore);

  const { carTreeData, carTreeLoading } = useSelector(
    (state: any) => state.carTreeStore
  );

  const checkCarInfosRef = useRef<any>([]); // 存储选择的车辆信息(searchAlive模式下)

  const searchValueRef = useRef(""); // 存储搜索的值

  const selectedFilterKeyRef = useRef(defaultFilterKey); // 存储车辆过滤下拉菜单选择的key值

  const activeReloadRef = useRef(false); // 主动触发数据刷新

  const filterReloadRef = useRef(false); // 车辆过滤菜单触发数据刷新

  const [allCarData, setAllCarData] = useState([]); // 全量树形车辆数据

  const [carData, setCarData] = useState([]); // 展示的树形车辆数据

  const [loading, setLoading] = useState<boolean>(false); // 树形车辆数据获取loading控制

  const [expandedKeys, setExpandedKeys] = useState<any>([]); // 展开的keys

  const [selectedKeys, setSelectedKeys] = useState<any>([]); // 点击选择的keys

  const [checkedKeys, setCheckedKeys] = useState<any>([]); // 复选框选中的keys

  const [selectedFilterKey, setSelectedFilterKey] =
    useState<string>(defaultFilterKey); // 车辆过滤下拉菜单选择的key

  useEffect(() => {
    if (carTreeData.length === 0) {
      dispatch(initCarTreeData(true, true) as any);
    } else {
      dispatch({
        type: "addCarTree",
      });
    }

    return () => {
      dispatch(removeCarTreeData() as any);
    };
  }, []);

  // 有传入searchPlateNumber时触发车辆树的搜索

  useEffect(() => {
    if (searchPlateNumber && allCarData.length) {
      handleSearch(searchPlateNumber);

      form.setFieldsValue({ plateNumber: searchPlateNumber });
    }
  }, [searchPlateNumber, allCarData]);

  // 暴露给父组件调用的方法

  useImperativeHandle(
    ref,

    () => ({
      // 重新请求车辆树数据以及重置选择和勾选项

      reloadData: () => {
        activeReloadRef.current = true;

        dispatch(initCarTreeData(true, false) as any);

        setSelectedKeys([]);

        setCheckedKeys([]);

        checkCarInfosRef.current = [];
      },
    }),

    []
  );

  // 处理车辆的树形结构数据

  const operateCarTree = (data: any) => {
    if (Array.isArray(data) && data.length > 0) {
      const newData: any = [];

      data.forEach((item) => {
        const obj = { ...item };

        obj.key = obj.id;

        obj.selectable = selectableTypes.includes(obj.type);

        if (item.type === "car") {
          obj.newIsOnline = false; // 判断是否在线的字段(自己定义的)

          obj.icon = <CarOutlined />; // 显示的车辆icon

          if (item.jt808DeviceId) {
            // 安装部标机车辆

            if (item.jt808IsOnline) {
              if (item.acc) {
                // 在线且视频可播放

                obj.icon = <CarOutlined style={{ color: "#00ff00" }} />;

                obj.newIsOnline = true;
              } else {
                // 在线但视频不能播放

                obj.icon = <CarOutlined style={{ color: "#f6d10a" }} />;
              }
            }
          } else if (item.isOnline) {
            // 没有安装部标机车辆在线

            obj.icon = <CarOutlined style={{ color: "#00ff00" }} />;

            obj.newIsOnline = true;
          }

          if (showChannel) {
            // 显示部标机通道数据

            obj.children = (obj.channelNoList || []).map((channel: number) => ({
              type: "channel",

              title: `CH${channel}`,

              key: `${obj.metaId}_${channel}`,

              id: `${obj.metaId}_${channel}`,

              channel,

              jt808DeviceId: obj.jt808DeviceId,

              terminalPhone: obj.terminalPhone,

              version: obj.version,

              checkable: false,

              selectable: selectableTypes.includes("channel"),

              disabled: obj.newIsOnline ? false : true, // 部标机不在线时通道不可选

              icon: <VideoCameraOutlined />,

              plateNumber: obj.title,

              carId: obj.metaId,
            }));
          }
        }

        if (Array.isArray(item.children) && item.children.length > 0) {
          const tempData = operateCarTree(item.children);

          obj.children = tempData;
        }

        // 有过滤条件时进行过滤

        if (selectedFilterKeyRef.current !== "all") {
          // 过滤条件对应所要判断的字段

          const filterKeyObj: { [key: string]: string } = {
            online: "newIsOnline",

            hasJtDevice: "jt808DeviceId",
          };

          // 要判断的字段

          const judgeField = filterKeyObj[selectedFilterKeyRef.current];

          if (obj[judgeField] || (obj.children?.length && !obj.channelNoList)) {
            // 匹配对应过滤的过滤条件或者有子项(且子项不只是部标机通道数据时)时保留数据

            newData.push(obj);
          }
        } else {
          newData.push(obj);
        }
      });

      return newData;
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (carTreeData.length) {
      let newCarData = operateCarTree(carTreeData);

      // 多分组用户车辆树形数据特殊处理(兼容选择多分组形式但只有一个分组的形式)

      if (
        userDetail.multiCate &&
        JSON.parse(userDetail.multiCate)?.length > 1
      ) {
        newCarData = filterMultipleCateData(
          newCarData,

          JSON.parse(userDetail.multiCate)
        );
      }

      setAllCarData(newCarData);

      if (
        searchValueRef.current &&
        !activeReloadRef.current &&
        !filterReloadRef.current
      ) {
        // 重新刷新时根据搜索的内容进行数据过滤(主动触发数据刷新和车辆过滤菜单触发数据刷新不进行过滤)

        const filterCarData = filterTreeData(
          newCarData,

          searchValueRef.current,

          "title"
        );

        setCarData(filterCarData);
      } else {
        setCarData(newCarData);
      }
    }
  }, [carTreeData]);

  // 选中树节点

  const onTreeSelect = (selectedKeys: any, e: any) => {
    if (e.selected) {
      // 只有选中操作时才更新(取消Tree组件默认点击切换选中的功能)

      setSelectedKeys(selectedKeys);

      const selectCarInfo = e.node;

      if (!clickReplaceSelect) {
        // 避免有clickReplaceSelect参数时treeSelect方法调用两次

        treeSelect && treeSelect(selectCarInfo);
      }
    }

    if (clickReplaceSelect) {
      // 只要点击节点就触发treeSelect方法

      const selectCarInfo = e.node;

      treeSelect && treeSelect(selectCarInfo);
    }
  };

  // 勾选复选框

  const onCheck = (checkedKeys: any, e: any) => {
    const checkedNodes = e.checkedNodes;

    // 勾选的车辆id

    const checkCarIds: Array<number> = [];

    // 勾选的车辆信息

    const checkCarInfos: any = [];

    // 勾选的车辆在Tree中的id(用于受控Tree组件的选中)

    const checkTreeIds: Array<number | string> = [];

    checkedNodes.forEach((item: any) => {
      if (item.type === "car") {
        // 存储车辆信息

        checkCarIds.push(item.metaId);

        checkCarInfos.push(item);
      }

      if (!item?.children?.length || item.type === "car") {
        /**

         * 只处理最后一层级节点的id或者是车辆节点的id(特殊处理车辆节点是为了兼容车辆下有通道数据的情况)

         * 不使用checkedKeys进行受控是防止在搜索显示部分数据时选中的节点在全量显示数据时造成多余的数据选中

         */

        checkTreeIds.push(item.id);
      }
    });

    setCheckedKeys(checkTreeIds);

    treeCheck && treeCheck(checkCarIds, checkCarInfos);
  };

  // searchAlive模式下的勾选复选框

  const onSearchAliveCheck = (keys: any, e: any) => {
    // 根据当前操作的节点获取受控选中和车辆的信息

    const _getCurrentCheckInfos = (data: any) => {
      if (Array.isArray(data) && data.length > 0) {
        const allIds: any = []; // 受控勾选的数据

        const allCarInfos: any = []; // 所有车辆的信息

        const deep = (innerData: any) => {
          innerData.forEach((item: any) => {
            if (
              (!item?.children?.length && item.type !== "channel") ||
              item.type === "car"
            ) {
              /**

               * 只处理最后一层级节点的id或者是车辆节点的id(特殊处理车辆节点是为了兼容车辆下有通道数据的情况)

               * 不使用checkedKeys进行受控是防止在搜索显示部分数据时选中的节点在全量显示数据时造成多余的数据选中

               */

              allIds.push(item.id);
            }

            if (item.type === "car") {
              allCarInfos.push(item);
            }

            if (Array.isArray(item.children) && item.children.length > 0) {
              deep(item.children);
            }
          });
        };

        deep(data);

        return { allIds, allCarInfos };
      } else {
        return { allIds: [], allCarInfos: [] };
      }
    };

    // 通过本次勾选或取消勾选的节点计算Tree需要受控选中或受控取消选中的id值和选中的或取消选中的车辆信息

    const { allIds, allCarInfos } = _getCurrentCheckInfos([e.node]);

    if (e.checked) {
      // 勾选操作存储新勾选的信息

      setCheckedKeys(uniq([...checkedKeys, ...allIds]));

      checkCarInfosRef.current = uniqBy(
        [...checkCarInfosRef.current, ...allCarInfos],

        "metaId"
      );
    } else {
      // 取消勾选操作删除取消勾选的信息

      const filterCheckedKeys = checkedKeys.filter(
        (item: any) => !allIds.includes(item)
      );

      setCheckedKeys(filterCheckedKeys);

      checkCarInfosRef.current = checkCarInfosRef.current.filter(
        (item: any) =>
          !allCarInfos.find((item1: any) => item.metaId === item1.metaId)
      );
    }

    treeCheck &&
      treeCheck(
        checkCarInfosRef.current.map((item: any) => item.metaId),

        checkCarInfosRef.current
      );
  };

  // 搜索框搜索

  const handleSearch = async (value: string) => {
    searchValueRef.current = value;

    activeReloadRef.current = false;

    filterReloadRef.current = false;

    if (value) {
      setLoading(true);

      const expandData: any = [];

      setTimeout(() => {
        const filterData = filterTreeData(
          allCarData,

          value,

          "title",

          (item: any) => expandData.push(item.key)
        );

        setCarData(filterData);

        setExpandedKeys(expandData);

        setLoading(false);
      }, 100);
    } else {
      setExpandedKeys([]);

      setCarData(allCarData);
    }
  };

  // 防抖的搜索框搜索

  const debounceHandleSearch = useDebounce(handleSearch, [allCarData]);

  // 过滤下拉菜单选择

  const onFilterMenuSelect = (item: any) => {
    if (item.key !== selectedFilterKey) {
      setSelectedFilterKey(item.key);

      selectedFilterKeyRef.current = item.key;

      searchValueRef.current = "";

      filterReloadRef.current = true;

      form.setFieldsValue({ plateNumber: "" });

      dispatch(initCarTreeData(true, false) as any);
    }
  };

  // 车辆数据过滤下拉菜单配置

  const filterDropdown: any = [
    {
      key: "all",

      label: "全部车辆",
    },

    {
      key: "online",

      label: "在线车辆",
    },

    {
      key: "hasJtDevice",

      label: "安装部标机车辆",
    },
  ];

  return (
    <div className={styles.carTree}>
      <div className={styles.title}>
        <div>
          车辆列表
          {showFilter && (
            <Dropdown
              menu={{
                items: filterDropdown,

                onClick: onFilterMenuSelect,

                selectedKeys: [selectedFilterKey],
              }}
              getPopupContainer={(node: HTMLElement) => node}
            >
              <FilterOutlined className={styles.filterIcon} />
            </Dropdown>
          )}
        </div>

        {extraTitle}
      </div>

      <Form form={form} autoComplete="off">
        <Form.Item name="plateNumber" noStyle>
          <Input
            onChange={(e: any) => debounceHandleSearch(e.target.value)}
            placeholder={
              allCarData.length
                ? "可输入车牌号/分组进行搜索"
                : "待数据加载完进行搜索"
            }
            disabled={!allCarData.length}
          />
        </Form.Item>
      </Form>

      <LoadingWrapper spinning={carTreeLoading || loading} autoHeight>
        <div className={styles.tree}>
          <Tree
            checkable={checkable}
            checkedKeys={checkedKeys}
            selectedKeys={selectedKeys}
            treeData={carData}
            onSelect={onTreeSelect}
            onCheck={(selectedKeys: any, e: any) => {
              searchAlive
                ? onSearchAliveCheck(selectedKeys, e)
                : onCheck(selectedKeys, e);
            }}
            showLine={{ showLeafIcon: false }}
            showIcon
            expandedKeys={expandedKeys}
            onExpand={(newExpandedKeys: any) => {
              setExpandedKeys(newExpandedKeys);
            }}
          />
        </div>
      </LoadingWrapper>
    </div>
  );
};

export default forwardRef(CarTree);
