// extracted by mini-css-extract-plugin
export default {"fixPageContentBox":"channelAssessment_fixPageContentBox__DaoOs","topTableItem":"channelAssessment_topTableItem__3Fhg3","topTableItemMain":"channelAssessment_topTableItemMain__71nfn","topTableItemMainThree":"channelAssessment_topTableItemMainThree__O2t-4","topTableItemMainSix":"channelAssessment_topTableItemMainSix__O4LEZ","topTableItemMainC":"channelAssessment_topTableItemMainC__wfF8S","topTableItemMainP":"channelAssessment_topTableItemMainP__6quCd","topTableItemMainR":"channelAssessment_topTableItemMainR__4nqhq"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"fixPageContentBox\":\"channelAssessment_fixPageContentBox__DaoOs\",\"topTableItem\":\"channelAssessment_topTableItem__3Fhg3\",\"topTableItemMain\":\"channelAssessment_topTableItemMain__71nfn\",\"topTableItemMainThree\":\"channelAssessment_topTableItemMainThree__O2t-4\",\"topTableItemMainSix\":\"channelAssessment_topTableItemMainSix__O4LEZ\",\"topTableItemMainC\":\"channelAssessment_topTableItemMainC__wfF8S\",\"topTableItemMainP\":\"channelAssessment_topTableItemMainP__6quCd\",\"topTableItemMainR\":\"channelAssessment_topTableItemMainR__4nqhq\"}";
        // 1728725594945
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  