// extracted by mini-css-extract-plugin
export default {"carDiagnosis":"carSitution_carDiagnosis__y5t2h","searchBox":"carSitution_searchBox__iO3Cp","carInfoBox":"carSitution_carInfoBox__An3OX","carInfoTitle":"carSitution_carInfoTitle__pwxmW","carInfoContent":"carSitution_carInfoContent__1O0Xm","carInfoPerson":"carSitution_carInfoPerson__un6-u","carInfoDriver":"carSitution_carInfoDriver__rsiFH","personPhone":"carSitution_personPhone__ZBfaF","driverPhone":"carSitution_driverPhone__O+QCI","carMap":"carSitution_carMap__UjjnM"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"carDiagnosis\":\"carSitution_carDiagnosis__y5t2h\",\"searchBox\":\"carSitution_searchBox__iO3Cp\",\"carInfoBox\":\"carSitution_carInfoBox__An3OX\",\"carInfoTitle\":\"carSitution_carInfoTitle__pwxmW\",\"carInfoContent\":\"carSitution_carInfoContent__1O0Xm\",\"carInfoPerson\":\"carSitution_carInfoPerson__un6-u\",\"carInfoDriver\":\"carSitution_carInfoDriver__rsiFH\",\"personPhone\":\"carSitution_personPhone__ZBfaF\",\"driverPhone\":\"carSitution_driverPhone__O+QCI\",\"carMap\":\"carSitution_carMap__UjjnM\"}";
        // 1728725595038
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  