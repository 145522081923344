/**
 * 车辆管理（优化）
 */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Form, Row, Col, Button, Input, Select, Popconfirm, message, DatePicker, Collapse } from 'antd';
import dayjs from 'dayjs';
import {
  carListRequests,
  carListRequestPath,
  CarListRequestParams,
  unbindDeviceRequest,
  carListRequest
} from '@/service/carManage';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import ContactModal from '@/components/contactModal';
import ParamsModal from '@/components/paramsModal';
import VersionModal from '@/components/versionModal';
import PositionModal from '@/components/positionModal';
import KlsDeviceStatusModal from '@/components/klsDeviceStatusModal';
import PermissionButton from '@/components/permissionButton';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import OperateModal from './components/operateModal';
import FavoriteCarModal from '@/pages/myCar/components/favoriteCarModal';
import {
  snColumnConfig,
  cameraSnColumnConfig,
  cateGroupColumnConfig,
  frameNumberColumnConfig,
  carBrandColumnConfig,
  carModelColumnConfig
} from '@/constants/tableColumn';
import { useNotFirstRenderEffect, useUserCateIdArr } from '@/utils/ownHooks';
import { getInnerPermissionEnable } from '@/utils/permission';
import { carFilterTypeData } from '@/constants/car';
import {
  deviceConfigData,
  deviceTypeData,
  DeviceTypeEnum,
  carType,
  carState,
  deviceState,
  deviceCategoryData,
} from '@/constants/deviceManage';
import { tagListRequest } from '@/service/tagManage';
import { TagCategoryEnum } from '@/constants/tagManage';
import styles from '@/styles/pageContent.module.scss';
import { insureListRequest } from '@/service/insurerManage';
import { cateAllCompanyRequest } from '@/service/fleetRisk';
import { companyListRequest } from '@/service/companyManage';
import { channelListRequest } from '@/service/channelRecode';
import UnbindModal from './components/unbindModal';
const { RangePicker } = DatePicker;

const CarManage = () => {
  const cateIdArr = useUserCateIdArr();
  const userStore = useSelector((state: any) => state.userStore);
  const tabPagesStore = useSelector((state: any) => state.tabPagesStore);
  const { userDetail } = userStore;
  const { activeKey, tabParams } = tabPagesStore;
  const [carData, setCarData] = useState([]); // 车辆数据
  const [totalCount, setTotalCount] = useState(0); // 车辆总数
  const [pageNum, setPageNum] = useState(1); // 页码
  const [pageSize, setPageSize] = useState(40); // 每一页显示的数量
  const [serachParams, setSerachParams] = useState({
    cate_id: cateIdArr,
  }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [loading, setLoading] = useState(false);
  const [carBusinessUnit, setCarBusinessUnit] = useState([]); // 获取业务渠道
  const [channelOptions, setChannelOptions] = useState([]); // 获取业务渠道选项
  const [carServeState, setCarServeState] = useState([]); // 获取运维状态选项
  const [CarType, setCarType] = useState<any>([]); // 车辆类型
  const [commercialCompany, setCommercialCompany] = useState<any>([]); // 承保保司
  const [allCompany, setAllCompany] = useState<any>([]); // 车队选择
  const [serviceState, setServiceState] = useState<any>([]); // 服务状态
  const [form] = Form.useForm();
  // 获取车辆类型
  const getCarType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.carTypeData],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any, index: any) => {
      const { title, id } = item;
      tags.push({ label: title, value: index + 1 });
    });
    setCarType(tags);
  }

  // 携带参数跳转时的处理
  const operateTabParams = () => {
    const newTabparams = { ...tabParams };
    if (!newTabparams.filterType) {
      delete newTabparams.filterType;
    }
    form.setFieldsValue({
      sn: '',
      plateNumber: '',
      frameNumber: '',
      cate_id: newTabparams?.cate_id || [],
      filterType: newTabparams?.filterType || '',
      phone: '',
      compulsoryEndDate: null,
      commercialEndDate: null,
      hasInsured: null,
      deviceType: null,
      needModem: null,
      buildHome: null,
      needVideoMachine: null
    })
    setSerachParams(newTabparams);
    getCarData({ page: 1, limit: 40, ...newTabparams });
  }

  // 获取承保保司
  const getCommercialCompany = async () => {
    const res = await insureListRequest('');
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { name, id } = item;
      tags.push({ label: name, value: id });
    });
    setCommercialCompany(tags);
  }

  // 获取车队
  const getCateAllCompanyData = async () => {
    const res = await companyListRequest({ page: 1, limit: 20000, name: '' });
    const data = res.data?.data || [];
    //循环遍历data 
    const aa: any = []
    data.forEach((item: any) => {
      aa.push({ label: item.name, value: item.id })
    })
    setAllCompany(aa)
  }

  // 获取服务状态
  const getServiceState = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.serviceState],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setServiceState(tags);
  }

  useEffect(() => {
    getCarType()
    getBusinessUnitType()
    // getBusinessStateType()
    getCommercialCompany()
    getCateAllCompanyData()
    getServiceState()
    if (tabParams) {
      operateTabParams();
    } else {
      getCarData({ page: 1, limit: 40, cate_id: cateIdArr });
    }
    getServiceProblemType();
  }, []);

  // 获取业务渠道
  const getBusinessUnitType = async () => {
    // const res = await tagListRequest({
    //   tagSorts: [TagCategoryEnum.businessUnit],
    // });
    const res = await channelListRequest({ page: 1, limit: 50000, name: '' });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { name, id } = item;
      tags.push({ label: name, value: id });
    });
    setCarBusinessUnit(tags);

    const channelOptions = (res?.data?.data || []).map((item: { id: number, name: string }) => {
      return {
        label: item.name,
        value: item.id,
      }
    });
    setChannelOptions(channelOptions);
  }

  useNotFirstRenderEffect(() => {
    if (activeKey === '/car-admin/car-manage' && tabParams) {
      operateTabParams();
    }
  }, [activeKey, tabParams]);

  // 获取车辆数据
  const getCarData = async (params: CarListRequestParams) => {
    try {
      setCarData([]);
      setLoading(true);
      // 安装队长角色时接口添加此字段(只展示该安装队长的车辆)
      if (!getInnerPermissionEnable('/car-admin/car-manage/list-params-normal')) {
        params.installCaptain = userDetail.nickname;
      }
      const res = await carListRequest(params);

      const resData = res.data?.data || [];
      const resDataWithIdxKey = resData.map((item: any, index: number) => {
        return {
          ...item,
          key: `${item.carId}-${index}`,
        }
      })
      setCarData(resDataWithIdxKey)
      setTotalCount(res.data?.count || 0)
      setPageNum(params.page)
      setLoading(false);
    } catch (error) {
      console.warn('error', error);
      setLoading(false);
    }
  }

  // 处理搜索的参数
  const operateSearchParams = (params: any, isExport?: boolean) => {

    const newParams = { ...params };
    if (newParams.delTime) {
      newParams.delTimeStart = dayjs(params.delTime[0]).format('YYYY-MM-DD')
      newParams.delTimeEnd = dayjs(params.delTime[1]).format('YYYY-MM-DD')
      delete newParams.delTime;
    }
    newParams.sn = (params.sn || '').trim();
    newParams.plate_number = (params.plate_number || '').trim();
    newParams.frameNumber = (params.frameNumber || '').trim();
    newParams.phone = (params.phone || '').trim();
    newParams.compulsoryEndDate = params.compulsoryEndDate ? dayjs(params.compulsoryEndDate).format('YYYY-MM-DD') : '';
    newParams.commercialEndDate = params.commercialEndDate ? dayjs(params.commercialEndDate).format('YYYY-MM-DD') : '';
    if (isExport) {
      newParams.export = true;
    }
    return newParams;
  }

  // 搜索
  const onSearch = async (values: any) => {
    if (values.cate_id == '') {
      message.warning('分类选择不能为空')
    } else {
      const newSearchParams = operateSearchParams(values);
      setSerachParams(newSearchParams);
      getCarData({
        page: 1,
        limit: pageSize,
        ...newSearchParams,
      });
    }
  };

  // 翻页器信息改变
  const onPageChange = (page: number, newPageSize: number) => {
    const newPageNum = newPageSize !== pageSize ? 1 : page;
    setPageNum(newPageNum);
    setPageSize(newPageSize);
    getCarData({
      page: newPageNum,
      limit: newPageSize,
      ...serachParams,
    })
  }

  // 添加编辑之后重新获取列表数据
  const reloadData = () => {
    getCarData({
      page: pageNum,
      limit: pageSize,
      ...serachParams,
    })
  }

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    if (values.cate_id == '') {
      message.warning('分类选择不能为空')
    } else {
      return operateSearchParams(values, true);
    }
  }

  // 运维组选择
  const groupChange = (value: string, options: any) => {
    if (value) {
      form.setFieldsValue({
        cate_id: options.cateId,
      })
    }
  }

  // 车辆解绑设备
  const unbindDevice = async (data: any) => {
    setOperateInfo({ type: 'unbind', data: data })
  }

  const bindCallback = async (params: any) => {
    const res = await unbindDeviceRequest(params);
    if (res.data?.code === 0) {
      message.success('解绑成功');
      setOperateInfo({})
      reloadData()
    }
  }

  // 渲染设备状态列
  const renderDeviceStatusCloumn = (value: string, item: any) => {
    // 昆仑山设备显示按钮
    if (item.device_type && (Number(item.device_type) === DeviceTypeEnum.kunlunshan1
      || Number(item.device_type) === DeviceTypeEnum.kunlunshan2
      || Number(item.device_type) === DeviceTypeEnum.kunlunshan3
      || Number(item.device_type) === DeviceTypeEnum.adas3
      || Number(item.device_type) === DeviceTypeEnum.kunlunshan4
      || Number(item.device_type) === DeviceTypeEnum.kunlunshan5
    )) {
      return (
        <div className={styles.tableOperateColumn}>
          <Button type="link" onClick={() => setOperateInfo({ type: 'kls', data: item })}>设备状态</Button>
        </div>
      )
    }
    return (
      <RenderEllipsisColumn text={value || ''} />
    )
  }

  // 获取运维状态
  const getServiceProblemType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.carServeState],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setCarServeState(tags);
  };

  // 表格列显示
  const columns = [
    { title: '车牌号', dataIndex: 'plate_number', width: 110 },
    { title: '车架号', dataIndex: 'frame_number', width: 110 },
    { title: '业务模式', dataIndex: 'mode', width: 110 },
    { title: '保司', dataIndex: 'insurance', width: 110 },
    { title: '渠道', dataIndex: 'channel', width: 110 },
    { title: '车队', dataIndex: 'company', width: 110 },

    { title: '车辆类型', dataIndex: 'carType', width: 110, render: (value: number) => carType(value), },
    { title: '品牌', dataIndex: 'car_brand', width: 110 },
    { title: '车辆型号', dataIndex: 'car_model', width: 110 },
    { title: '制造年月', dataIndex: 'manufactureDate', width: 110 },
    { title: '车辆注册日期', dataIndex: 'registerTime', width: 110 },
    { title: '安装设备类型', dataIndex: 'deviceTypeStr', width: 110 },
    { title: '设备类型大类', dataIndex: 'deviceTypeCategoryStr', width: 110 },
    { title: '硬件配置类型', dataIndex: 'hardwareConfigTypeStr',  width: 220},
    { title: 'adas设备SN', dataIndex: 'adas_sn', width: 110 },
    { title: 'aeb设备SN', dataIndex: 'sn', width: 110 },
    { title: '司机', dataIndex: 'car_driver', width: 110 },
    { title: '司机电话', dataIndex: 'car_driver_phone', width: 110 },
    { title: '车辆负责人', dataIndex: 'car_principal', width: 110 },
    { title: '车辆负责人电话', dataIndex: 'car_principal_phone', width: 110 },
    { title: '业务负责人', dataIndex: 'ooe', width: 110 },
    { title: '业务负责人电话', dataIndex: 'ooePhone', width: 110 },
    { title: '安装时间', dataIndex: 'installTime', width: 110 },
    { title: '安装人员', dataIndex: 'installCaptain', width: 110 },

    // { title: '拆除人员', dataIndex: 'removalUser', width: 110 },
    { title: '保单号', dataIndex: 'commercialNumber', width: 110 },
    { title: '合同号', dataIndex: 'deviceContractNo', width: 110 },
    { title: '车辆安装时所在城市', dataIndex: 'installAddress', width: 110 },
    { title: '创建时间', dataIndex: 'created_at', width: 110 },
    { title: '更新时间', dataIndex: 'updated_at', width: 110 },
    { title: '车辆工作状态', dataIndex: 'vehicleState', width: 110 },
    { title: '设备工作状态', dataIndex: 'deviceState', width: 110 },
    { title: '承保状态', dataIndex: 'businessState', width: 110 },
    { title: '合同状态', dataIndex: 'contractState', width: 110 },
    { title: 'AEB设备在线', dataIndex: 'online', width: 110, render: (value: any, item: any) => {
      if (item.deviceTypeStr === 'TLY4.0') {
        return '不涉及'
      } else {
        return value === '时间有误' ? '是' : value
      }
    } },
    { title: 'ADAS设备在线', dataIndex: 'adasOnline', width: 110,  render: (value: any, item: any) => {
        return value === '时间有误' ? '是' : value
    } },
    { title: '设备拆除状态', dataIndex: 'deviceRemoveState', width: 110 },
    { title: '拆除时间', dataIndex: 'deviceRemoveTime', width: 110 },
    { title: '设备拆除原因', dataIndex: 'operateRemark', width: 110 },
    {
      title: '是否安装部标机',
      dataIndex: 'needVideoMachine',
      render: (value: boolean) => value ? "是" : "否",
      width: 110
    },
    { title: '备注', dataIndex: 'operateRemark', width: 110 },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 440,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType='/car-admin/car-manage/edit'
              type="link"
              onClick={() => setOperateInfo({ type: 'edit', data: item })}
            >
              编辑
            </PermissionButton>
            <PermissionButton
              operateType='/car-admin/car-manage/contact'
              type="link"
              onClick={() => setOperateInfo({ type: 'contact', data: item })}
            >
              联系方式
            </PermissionButton>
            <Button type="link" onClick={() => setOperateInfo({ type: 'params', data: item })}>参数信息</Button>
            <Button type="link" onClick={() => setOperateInfo({ type: 'version', data: item })}>版本信息</Button>

            <Button type="link" onClick={() => setOperateInfo({ type: 'position', data: item })}>最近位置</Button>
            {/*由原来的 有设备id才显示 改成  有aeb或者adas设备的才显示*/}
            {/*{Boolean(item.deviceId) && <Popconfirm*/}
            {/* {Boolean(item.sn || item.adas_sn) && <Popconfirm
              title="确定要解绑该车辆设备吗？"
              onConfirm={() => unbindDevice(item.carId)}
            >
              <PermissionButton
                operateType='/car-admin/car-manage/unbind'
                type="link"
              >

                解绑设备
              </PermissionButton>
            </Popconfirm>} */}
            {Boolean(item.sn || item.adas_sn) && <PermissionButton
              onClick={() => unbindDevice(item)}
              operateType='/car-admin/car-manage/unbind'
              type="link"
            >

              解绑设备
            </PermissionButton>}
            {item.isFavorite ?
              <Button type="text">已关注</Button> :
              <PermissionButton
                type="link"
                operateType='/car-admin/car-manage/follow'
                onClick={() => setOperateInfo({
                  type: 'favorite',
                  data: { plateNumber: item.plate_number, carId: item.id },
                  operateType: 'add'
                })}
              >
                关注
              </PermissionButton>
            }
          </div>
        )
      },
    },
  ]


  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Collapse defaultActiveKey={'searchForm'}>
        <Collapse.Panel key="searchForm" header="筛选信息">
          <Form onFinish={onSearch} layout="vertical" autoComplete='off' form={form}>
            <Row gutter={24} wrap align='bottom'>
              <Col span={4}>
                <Form.Item
                  label="车队选择"
                  name="companyId"
                >
                  <Select
                    showSearch
                    placeholder="请选择"
                    style={{ width: '100%' }}
                    options={allCompany}
                    allowClear
                    filterOption={(input, option) => {
                      const label = (option?.label || "") as string;
                      return label.indexOf(input.trim()) >= 0;
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="设备SN" name="sn">
                  <Input placeholder='请输入' />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="车牌号" name="plateNumber">
                  <Input placeholder='请输入' onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('plateNumber', newV)}} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="车架号" name="frameNumber">
                  <Input placeholder='请输入' onChange={(e) => {const newV = e.target.value.replace(/\s+/g, ''); form.setFieldValue('frameNumber', newV)}} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="分类选择" name="cate_id" initialValue={serachParams.cate_id}>
                  <CategoryTreeSelect treeCheckable />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="车辆状态" name="vehicleState">
                  <Select placeholder="请选择车辆状态" options={carState} allowClear showArrow showSearch
                          optionFilterProp="label" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="保险服务状态" name="businessState">
                  <Select
                    placeholder="请选择保险服务状态"
                    options={serviceState}
                    allowClear
                    showArrow
                    showSearch
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="车辆指标筛选" name="filterType">
                  <Select options={carFilterTypeData} allowClear />
                </Form.Item>
              </Col>
              {/*<Col span={4}>*/}
              {/*  <Form.Item label="设备类型大类" name="deviceTypeCategory">*/}
              {/*    <Select placeholder="请选择设备类型" options={deviceCategoryData} allowClear*/}
              {/*            optionFilterProp="label"/>*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              <Col span={4}>
                <Form.Item label="设备类型" name="deviceType">
                  <Select placeholder="请选择设备类型" mode="multiple" options={deviceTypeData} allowClear showArrow
                          showSearch optionFilterProp="label" />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item label="硬件配置类型" name="hardwareConfigType">
                  <Select placeholder='请选择硬件配置类型' options={deviceConfigData} allowClear showArrow showSearch
                          mode="multiple" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="是否建群" name="buildHome">
                  <Select
                    placeholder='请选择是否建群'
                    options={[
                      { label: '是', value: '是' },
                      { label: '否', value: '否' },
                    ]}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="车辆类型" name="carType">
                  <Select placeholder='请选择车辆类型' options={CarType} allowClear />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="所属业务渠道" name="channelId">
                  <Select placeholder="请选择所属业务渠道" options={carBusinessUnit} allowClear showArrow showSearch
                          optionFilterProp="label" />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="承保保司" name="insuranceId">
                  <Select
                    placeholder="请选择承保保司"
                    options={commercialCompany}
                    allowClear
                    showArrow
                    showSearch
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="设备工作状态" name="deviceState">
                  <Select placeholder='请选择设备工作状态' options={carState} allowClear />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="是否安装部标机" name="needVideoMachine">
                  <Select
                    placeholder="请选择是否安装部标机"
                    options={[
                      { label: '是', value: true },
                      { label: '否', value: false }
                    ]}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="设备是否拆除" name="deviceRemoveState">
                  <Select
                    placeholder="请选择是否拆除"
                    options={[
                      { label: '是', value: '是' },
                      { label: '否', value: '否' }
                    ]}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="拆除时间" name="delTime">
                  <RangePicker allowClear={true} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="&nbsp;">
                  <div className={styles.searchBox}>
                    <Button type="primary" htmlType="submit">搜索</Button>
                    <ExportButton
                      url={carListRequestPath}
                      fileName='car'
                      getParamsFunc={getExportParams}
                      requestMethod="POST"
                      permissionKey='/car-admin/car-manage/export'
                    />

                    <PermissionButton
                      operateType='/car-admin/car-manage/add'
                      type="primary"
                      onClick={() => setOperateInfo({ type: 'add' })}
                    >
                      添加车辆
                    </PermissionButton>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>
      <CommonTable
        rowKey="key"
        columns={columns}
        dataSource={carData}
        pagination={{
          total: totalCount,
          current: pageNum,
          pageSize,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
        // tableInScreen={false}
        scroll={{ x: '100%', y: '100%' }}
      />
      {
        operateInfo.type === 'contact' &&
        <ContactModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {
        operateInfo.type === 'params' &&
        <ParamsModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {
        operateInfo.type === 'version' &&
        <VersionModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {
        operateInfo.type === 'position' &&
        <PositionModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {
        ['edit', 'add'].includes(operateInfo.type) &&
        <OperateModal
          data={operateInfo.data}
          type={operateInfo.type}
          channelOptions={channelOptions}
          closeModal={() => setOperateInfo({})}
          reloadData={reloadData}
        />
      }
      {operateInfo.type === 'kls' &&
        <KlsDeviceStatusModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'favorite' &&
        <FavoriteCarModal
          closeModal={() => setOperateInfo({})}
          reloadData={reloadData}
          data={operateInfo?.data}
          operateType={operateInfo?.operateType}
        />
      }
      {operateInfo.type === 'unbind' && <UnbindModal onOk={bindCallback} data={operateInfo.data} closeModal={() => { setOperateInfo({}) }}></UnbindModal>}
    </Card>
  )
}

export default CarManage;
