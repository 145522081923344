// extracted by mini-css-extract-plugin
export default {"firstRow":"alarmDetailModal_firstRow__5A9nm","secondRow":"alarmDetailModal_secondRow__CKm57","alarmDetailMap":"alarmDetailModal_alarmDetailMap__z2JAW"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"firstRow\":\"alarmDetailModal_firstRow__5A9nm\",\"secondRow\":\"alarmDetailModal_secondRow__CKm57\",\"alarmDetailMap\":\"alarmDetailModal_alarmDetailMap__z2JAW\"}";
        // 1728725596766
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  