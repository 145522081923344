// extracted by mini-css-extract-plugin
export default {"fleetRisk":"fleetRiskMonth_fleetRisk__K-i08","searchBox":"fleetRiskMonth_searchBox__eC8iA"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"fleetRisk\":\"fleetRiskMonth_fleetRisk__K-i08\",\"searchBox\":\"fleetRiskMonth_searchBox__eC8iA\"}";
        // 1728725594725
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  