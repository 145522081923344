// extracted by mini-css-extract-plugin
export default {"layout":"tabPages_layout__Q8dzP","layoutContent":"tabPages_layoutContent__dU95y","layoutSider":"tabPages_layoutSider__Pm64T","siderMenu":"tabPages_siderMenu__e9dUX","mainContentWrapper":"tabPages_mainContentWrapper__LDnzt","mainContent":"tabPages_mainContent__90Dn6"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"layout\":\"tabPages_layout__Q8dzP\",\"layoutContent\":\"tabPages_layoutContent__dU95y\",\"layoutSider\":\"tabPages_layoutSider__Pm64T\",\"siderMenu\":\"tabPages_siderMenu__e9dUX\",\"mainContentWrapper\":\"tabPages_mainContentWrapper__LDnzt\",\"mainContent\":\"tabPages_mainContent__90Dn6\"}";
        // 1728725590478
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  