// extracted by mini-css-extract-plugin
export default {"photoBigTitle":"maintenancePictureModal_photoBigTitle__RGQw-","photoItem":"maintenancePictureModal_photoItem__AGimh","photoItemTitle":"maintenancePictureModal_photoItemTitle__ZdmEc","photoItemPhoto":"maintenancePictureModal_photoItemPhoto__J0ym7"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"photoBigTitle\":\"maintenancePictureModal_photoBigTitle__RGQw-\",\"photoItem\":\"maintenancePictureModal_photoItem__AGimh\",\"photoItemTitle\":\"maintenancePictureModal_photoItemTitle__ZdmEc\",\"photoItemPhoto\":\"maintenancePictureModal_photoItemPhoto__J0ym7\"}";
        // 1728725596701
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  